import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { CurrencyInput, DatePicker, ModalConfirm, MuiSelect, MuiTextInput } from '@hometap/htco-components';

import useConfigurations from 'hooks/useConfigurations';
import SelectionYesNoSet from 'apps/track-details/tasks/components/TaskDetail/TaskSpecificContent/SelectionYesNoSet/SelectionYesNoSet';
import {
  validateDateNotInThePast,
  validateMinStringLength,
  validateMaxStringLength,
  validateUsd,
} from 'utils/validators';
import {
  MIN_ACCOUNT_NUMBER_LENGTH,
  MIN_MAX_ROUTING_NUMBER_LENGTH,
  PAYMENT_METHOD_TYPE,
} from './PaydownController/constants';
import { getLabel } from '../utils/liens';
import FlexibleTextarea from 'apps/track-details/ApplicationReview/components/FlexibleTextarea/FlexibleTextarea';
import { helperText } from 'utils/dates';
import DetailInformationBlock from 'components/DetailInformationBlock/DetailInformationBlock';

export const PaydownForm = ({
  paydownId,
  disabled,
  registerField,
  paymentMethod,
  accountNumber,
  routingNumber,
  goodThroughDate,
  updateFormData,
  updateLienFormData,
  errors,
  setErrors,
  lienKind,
  isPayoff,
  principalPaydownAmount,
  feePaydownAmount,
  homeValue,
  onPropertyReport,
}) => {
  const { lienPaymentMethods, lienKindsPrincipalAmountBalance } = useConfigurations();
  const cachedData = JSON.parse(sessionStorage.getItem(paydownId));
  const [cachedAccountNumber, setCashedAccountNumber] = useState(cachedData?.['accountNumber']);
  const [cachedRoutingNumber, setCashedRoutingNumber] = useState(cachedData?.['routingNumber']);
  const [cachedErrors, setCachedErrors] = useState(cachedData?.['errors']);

  const [wasOnPropertyReport, setWasOnPropertyReport] = useState(onPropertyReport === 'true');
  const [modalOpen, setModalOpen] = useState(false);

  const isMailCheckMethod = paymentMethod === PAYMENT_METHOD_TYPE.mailCheck;
  const isWireMethod = paymentMethod === PAYMENT_METHOD_TYPE.wire;
  const isOnPropertyReport = onPropertyReport === 'true';
  const isLienPrincipalAmountBalance = lienKindsPrincipalAmountBalance.includes(lienKind);

  useEffect(() => {
    // Trigger a confirmation popup if the lien form's value for
    // onPropertyReport changes from true to false or undefined
    if (wasOnPropertyReport && !isOnPropertyReport) {
      setWasOnPropertyReport(false);
      if (principalPaydownAmount) {
        setModalOpen(true);
      }
    } else if (isOnPropertyReport) {
      setWasOnPropertyReport(true);
    }
    // Prevent loop over setting 'wasOnPropertyReport'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnPropertyReport]);

  useEffect(() => {
    if (isWireMethod) {
      updateFormData({
        accountNumber: cachedAccountNumber,
        routingNumber: cachedRoutingNumber,
      });

      // show any validation errors from re-populating the fields
      if (!isEmpty(cachedErrors)) {
        ['accountNumber', 'routingNumber'].forEach(field => {
          if (cachedErrors[field]) {
            setErrors(field, cachedErrors[field].message, true);
          }
        });
        // clear paydown form stored errors
        setCachedErrors({});
      }
      // clear local storage
      sessionStorage.removeItem(paydownId);
    }
    // Silence linter error to prevent infinite loop between the two useEffect hooks.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWireMethod]);

  useEffect(() => {
    if (isLienPrincipalAmountBalance && isPayoff === 'false') {
      updateFormData({ feePaydownAmount: 0 });
    }

    const wirePaymentData = {};
    if (isWireMethod) {
      setCashedAccountNumber(accountNumber);
      setCashedRoutingNumber(routingNumber);
    }
    if (isMailCheckMethod) {
      updateFormData({
        routingNumber: '',
        accountNumber: '',
      });

      const errorsToStore = cachedErrors || {};
      // Store and clear any errors
      if (!routingNumber) {
        if (errors['routingNumber']) {
          errorsToStore['routingNumber'] = errors['routingNumber'];
        }
        setErrors('routingNumber', '', false);
      }
      if (!accountNumber) {
        if (errors['accountNumber']) {
          errorsToStore['accountNumber'] = errors['accountNumber'];
        }
        setErrors('accountNumber', '', false);
      }
      setCachedErrors(errorsToStore);
      wirePaymentData['accountNumber'] = cachedAccountNumber;
      wirePaymentData['routingNumber'] = cachedRoutingNumber;
      wirePaymentData['errors'] = errorsToStore;
      sessionStorage.setItem(paydownId, JSON.stringify(wirePaymentData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isWireMethod,
    isMailCheckMethod,
    updateFormData,
    accountNumber,
    routingNumber,
    setErrors,
    isPayoff,
    isLienPrincipalAmountBalance,
  ]);

  const paydownAmount = Number(principalPaydownAmount || '0.00') + Number(feePaydownAmount || '0.00');
  const handleModalConfirm = () => {
    setModalOpen(false);
    updateFormData({ principalPaydownAmount: undefined });
  };

  const handleModalCancel = () => {
    setModalOpen(false);
    updateLienFormData({ onPropertyReport: 'true' });
  };

  return (
    <>
      <div className="FormRow">
        <div className="FormItem">
          <SelectionYesNoSet
            label="Will this be a payoff or a paydown?"
            required
            showRequiredAsterisk={false}
            isWithMarginBottom={false}
            disabled={disabled}
            options={[
              {
                label: 'Payoff',
                value: 'true',
                disabled,
              },
              {
                label: 'Paydown',
                value: 'false',
                disabled,
              },
            ]}
            {...registerField('isPayoff')}
          />
        </div>
      </div>
      <div className="TwoItemFormRow">
        <div className="FormItem">
          <CurrencyInput
            label="Principal paydown amount (optional)"
            padFractionalZeros={true}
            disabled={disabled || !isOnPropertyReport}
            showRequiredAsterisk={false}
            validator={value => validateUsd(value, false)}
            {...registerField('principalPaydownAmount')}
          />
        </div>
        {/* Hide the Fee Paydown field for lien in the LIEN_KINDS_PRINCIPAL_AMOUNT_BALANCE */}
        {!(isLienPrincipalAmountBalance && isPayoff === 'false') && (
          <div className="FormItem">
            <CurrencyInput
              label="Fee paydown amount (optional)"
              padFractionalZeros={true}
              disabled={disabled}
              showRequiredAsterisk={false}
              validator={value => validateUsd(value, false)}
              {...registerField('feePaydownAmount')}
            />
          </div>
        )}
      </div>
      {paydownAmount > homeValue && (
        <div className="FormRow">
          <DetailInformationBlock
            type="error"
            text="This paydown exceeds the balance of the Home Value and indicates that this track should be disqualified. Please discuss with your Investment Manager."
          />
        </div>
      )}
      <div className="FormRow">
        <DatePicker
          label="Good through date (optional)"
          theme="outlined"
          disabled={disabled}
          className="FullWidthDatePicker"
          wrapperClassName="FullWidthDatePicker"
          showRequiredAsterisk={false}
          warning={goodThroughDate && validateDateNotInThePast(goodThroughDate)}
          showWarningIcon={true}
          {...registerField('goodThroughDate')}
          helperText={helperText}
        />
      </div>
      <div className="TwoItemFormRow">
        <div className="FormItem">
          <CurrencyInput
            label="Per diem (optional)"
            padFractionalZeros={true}
            disabled={disabled}
            showRequiredAsterisk={false}
            validator={value => validateUsd(value, false)}
            {...registerField('perDiem')}
          />
        </div>
        <div className="FormItem">
          <MuiSelect
            label="Method"
            classNamePrefix="MortgageLienSelect"
            options={lienPaymentMethods}
            disabled={disabled}
            theme="outlined"
            width="100%"
            showRequiredAsterisk={false}
            required={true}
            {...registerField('paymentMethod')}
          />
        </div>
      </div>
      <div className="FormRow">
        <div>
          <FlexibleTextarea
            label={getLabel({ label: 'Wiring instructions or address for check', required: isMailCheckMethod })}
            disabled={disabled || !paymentMethod}
            required={isMailCheckMethod}
            {...registerField('mailingInstructions')}
          />
        </div>
      </div>
      {!isMailCheckMethod && (
        <div className="TwoItemFormRow">
          <div className="FormItem">
            <MuiTextInput
              label="Target account number"
              theme="outlined"
              width="100%"
              disabled={disabled || !paymentMethod}
              required={isWireMethod}
              showRequiredAsterisk={false}
              validator={value => validateMinStringLength(value, MIN_ACCOUNT_NUMBER_LENGTH)}
              {...registerField('accountNumber')}
            />
          </div>
          <div className="FormItem">
            <MuiTextInput
              label="Routing number"
              theme="outlined"
              width="100%"
              disabled={disabled || !paymentMethod}
              required={isWireMethod}
              showRequiredAsterisk={false}
              validator={value =>
                validateMinStringLength(value, MIN_MAX_ROUTING_NUMBER_LENGTH) ||
                validateMaxStringLength(value, MIN_MAX_ROUTING_NUMBER_LENGTH)
              }
              {...registerField('routingNumber')}
            />
          </div>
        </div>
      )}
      {modalOpen && (
        <ModalConfirm
          theme="danger"
          open={modalOpen}
          header="Confirm Change to On Property Report"
          confirmText="Ok"
          onConfirm={handleModalConfirm}
          onCancel={handleModalCancel}
          onClose={handleModalCancel}
        >
          <p>This will zero out the Principal Paydown entry - do you wish to proceed?</p>
        </ModalConfirm>
      )}
    </>
  );
};

import { gql } from '@apollo/client';
import { TASK_DETAILS, UPDATED_TASK_CONDITION } from './fragments';
import { INSURANCE_POLICY_KINDS } from 'apps/track-details/data/constants/policyKinds';

export const UPDATE_TASK_CONDITIONS_COMPLETION = gql`
  mutation UpdateTaskConditionsCompletion(
    $taskId: IdentifierType!
    $conditionsToOpen: [IdentifierType!]
    $conditionsToComplete: [IdentifierType!]
  ) {
    updateTaskConditions(
      taskId: $taskId
      conditionsToOpen: $conditionsToOpen
      conditionsToComplete: $conditionsToComplete
    ) {
      conditions {
        ...UpdatedTaskCondition
      }
    }
  }
  ${UPDATED_TASK_CONDITION}
`;

export const COMPLETE_SENIOR_LIEN_TASK = gql`
  mutation CompleteSeniorLienTask($taskId: IdentifierType!, $resolution: TaskResolutionEnum!) {
    completeSeniorLienTask(taskId: $taskId, resolution: $resolution) {
      message
    }
  }
`;

export const MARK_DOCS_AS_REVIEWED = gql`
  mutation MarkDocumentsAsReviewed($documentIds: [IdentifierType!]!, $invalidationNotes: String) {
    markDocumentsAsReviewed(documentIds: $documentIds, invalidationNotes: $invalidationNotes) {
      ok
    }
  }
`;

export const OPEN_TASK = gql`
  mutation OpenTask($taskId: IdentifierType!) {
    openTask(taskId: $taskId) {
      task {
        ...TaskDetails
      }
    }
  }
  ${TASK_DETAILS}
`;

export const SAVE_TASK_NOTES = gql`
  mutation SaveTaskNotes($taskId: IdentifierType!, $notes: String!) {
    saveTaskNotes(taskId: $taskId, notes: $notes) {
      task {
        ...TaskDetails
      }
    }
  }
  ${TASK_DETAILS}
`;

export const CREATE_GOVERNMENT_ID = gql`
  mutation CreateGovernmentId(
    $applicantId: IdentifierType!
    $nameAsAppearsOnId: String!
    $trackId: IdentifierType!
    $birthDate: Date
    $expirationDate: Date
  ) {
    createGovernmentId(
      applicantId: $applicantId
      nameAsAppearsOnId: $nameAsAppearsOnId
      trackId: $trackId
      birthDate: $birthDate
      expirationDate: $expirationDate
    ) {
      governmentId {
        nameAsAppearsOnId
        expirationDate
        notes
        identifier
        birthDate
      }
    }
  }
`;

export const UPDATE_GOVERNMENT_ID = gql`
  mutation UpdateGovernmentId(
    $applicantId: IdentifierType!
    $nameAsAppearsOnId: String!
    $governmentIdId: IdentifierType!
    $birthDate: Date
    $expirationDate: Date
  ) {
    updateGovernmentId(
      applicantId: $applicantId
      nameAsAppearsOnId: $nameAsAppearsOnId
      governmentIdId: $governmentIdId
      birthDate: $birthDate
      expirationDate: $expirationDate
    ) {
      governmentId {
        nameAsAppearsOnId
        expirationDate
        notes
        identifier
        birthDate
      }
    }
  }
`;

export const UPDATE_HOMEOWNERS_INSURANCE_POLICY = gql`
  mutation UpdateHomeownersInsurancePolicy(
    $agentEmail: String
    $agentName: String
    $agentPhone: String
    $dwellingAmount: Int!
    $insurancePolicyId: IdentifierType!
    $uploadedDocIds: [IdentifierType!]
    $policyNumber: String!
    $propertyType: HomePropertyType!
    $annualCost: Decimal!
    $provider: String!
    $renewalDate: Date!
    $replacementCost: Boolean!
    $residenceType: HomeResidenceType!
    $notes: String
  ) {
    updateHomeownersInsurancePolicy(
      agentEmail: $agentEmail
      agentName: $agentName
      agentPhone: $agentPhone
      dwellingAmount: $dwellingAmount
      insurancePolicyId: $insurancePolicyId
      uploadedDocIds: $uploadedDocIds
      policyNumber: $policyNumber
      propertyType: $propertyType
      annualCost: $annualCost
      provider: $provider
      renewalDate: $renewalDate
      replacementCost: $replacementCost
      residenceType: $residenceType
      notes: $notes
    ) {
      home {
        identifier
        propertyType
        residenceType
      }
      insurancePolicy {
        identifier
      }
    }
  }
`;

export const UPDATE_HOMEOWNERS_COOP_INSURANCE_POLICY = gql`
  mutation UpdateHomeownersCoopInsurancePolicy($insurancePolicyId: IdentifierType!, $propertyType: HomePropertyType!) {
    updateHomeownersCoopInsurancePolicy(insurancePolicyId: $insurancePolicyId, propertyType: $propertyType) {
      home {
        identifier
        propertyType
      }
      insurancePolicy {
        identifier
      }
    }
  }
`;

export const CREATE_HOMEOWNERS_INSURANCE_POLICY = gql`
  mutation CreateHomeownersInsurancePolicy(
    $agentEmail: String
    $agentName: String
    $agentPhone: String
    $dwellingAmount: Int!
    $policyNumber: String!
    $propertyType: HomePropertyType!
    $annualCost: Decimal!
    $provider: String!
    $renewalDate: Date!
    $replacementCost: Boolean!
    $residenceType: HomeResidenceType!
    $trackId: IdentifierType!
    $uploadedDocIds: [IdentifierType!]!
  ) {
    createHomeownersInsurancePolicy(
      agentEmail: $agentEmail
      agentName: $agentName
      agentPhone: $agentPhone
      dwellingAmount: $dwellingAmount
      policyNumber: $policyNumber
      propertyType: $propertyType
      annualCost: $annualCost
      provider: $provider
      renewalDate: $renewalDate
      replacementCost: $replacementCost
      residenceType: $residenceType
      trackId: $trackId
      uploadedDocIds: $uploadedDocIds
    ) {
      home {
        identifier
        propertyType
        residenceType
      }
      insurancePolicy {
        identifier
      }
    }
  }
`;

export const CREATE_HOMEOWNERS_COOP_INSURANCE_POLICY = gql`
  mutation CreateHomeownersCoopInsurancePolicy(
    $propertyType: HomePropertyType!
    $trackId: IdentifierType!
    $uploadedDocIds: [IdentifierType!]!
  ) {
    createHomeownersCoopInsurancePolicy(
      propertyType: $propertyType
      trackId: $trackId
      uploadedDocIds: $uploadedDocIds
    ) {
      home {
        identifier
        propertyType
      }
      insurancePolicy {
        identifier
      }
    }
  }
`;

export const UPDATE_INSURANCE_POLICY_REPLACEMENT_COST = gql`
  mutation UpdateInsurancePolicyReplacementCost(
    $insurancePolicyId: IdentifierType!
    $kind: InsurancePolicyProofOfReplacementKindGraphene!
  ) {
    updateInsurancePolicyReplacementCost(insurancePolicyId: $insurancePolicyId, kind: $kind) {
      insurancePolicy {
        identifier
      }
    }
  }
`;

export const UPDATE_FLOOD_INSURANCE_POLICY = gql`
  mutation UpdateFloodInsurancePolicy(
    $insurancePolicyId: IdentifierType!
    $uploadedDocIds: [IdentifierType!]
    $agentName: String
    $agentEmail: String
    $agentPhone: String
    $annualCost: Decimal!
    $provider: String!
    $renewalDate: Date!
    $policyNumber: String!
    $dwellingAmount: Int!
    $notes: String
  ) {
    updateFloodInsurancePolicy(
      insurancePolicyId: $insurancePolicyId
      uploadedDocIds: $uploadedDocIds
      agentName: $agentName
      agentEmail: $agentEmail
      agentPhone: $agentPhone
      annualCost: $annualCost
      provider: $provider
      renewalDate: $renewalDate
      policyNumber: $policyNumber
      dwellingAmount: $dwellingAmount
      notes: $notes
    ) {
      insurancePolicy {
        agent
        agentEmail
        agentPhone
        provider
        renewalDate
        policyNumber
        dwellingAmount
        kind
        notes
        document {
          ... on Document {
            identifier
          }
        }
      }
    }
  }
`;

export const CREATE_FLOOD_INSURANCE_POLICY = gql`
  mutation CreateFloodInsurancePolicy(
    $trackId: IdentifierType!
    $uploadedDocIds: [IdentifierType!]!
    $agentName: String
    $agentEmail: String
    $agentPhone: String
    $annualCost: Decimal!
    $provider: String!
    $renewalDate: Date!
    $policyNumber: String!
    $dwellingAmount: Int!
  ) {
    createFloodInsurancePolicy(
      trackId: $trackId
      uploadedDocIds: $uploadedDocIds
      agentName: $agentName
      agentEmail: $agentEmail
      agentPhone: $agentPhone
      annualCost: $annualCost
      provider: $provider
      renewalDate: $renewalDate
      policyNumber: $policyNumber
      dwellingAmount: $dwellingAmount
    ) {
      insurancePolicy {
        agent
        agentEmail
        agentPhone
        provider
        renewalDate
        policyNumber
        dwellingAmount
        kind
        document {
          ... on Document {
            identifier
          }
        }
      }
    }
  }
`;

export const UPDATE_OTHER_INSURANCE_POLICY = gql`
  mutation UpdateOtherInsurancePolicy(
    $insurancePolicyId: IdentifierType!
    $uploadedDocIds: [IdentifierType!]
    $agentEmail: String
    $agentName: String
    $agentPhone: String
    $description: String
    $dwellingAmount: Int!
    $policyNumber: String!
    $provider: String!
    $renewalDate: Date!
    $notes: String
  ) {
    updateOtherInsurancePolicy(
      insurancePolicyId: $insurancePolicyId
      uploadedDocIds: $uploadedDocIds
      agentName: $agentName
      agentEmail: $agentEmail
      agentPhone: $agentPhone
      provider: $provider
      renewalDate: $renewalDate
      policyNumber: $policyNumber
      dwellingAmount: $dwellingAmount
      description: $description
      notes: $notes
    ) {
      insurancePolicy {
        agent
        agentEmail
        agentPhone
        provider
        renewalDate
        policyNumber
        dwellingAmount
        kind
        notes
        document {
          ... on Document {
            identifier
          }
        }
      }
    }
  }
`;

export const CREATE_OTHER_INSURANCE_POLICY = gql`
  mutation CreateOtherInsurancePolicy(
    $trackId: IdentifierType!
    $agentName: String
    $uploadedDocIds: [IdentifierType!]!
    $agentEmail: String
    $agentPhone: String
    $description: String
    $dwellingAmount: Int!
    $policyNumber: String!
    $provider: String!
    $renewalDate: Date!
  ) {
    createOtherInsurancePolicy(
      trackId: $trackId
      uploadedDocIds: $uploadedDocIds
      agentName: $agentName
      agentEmail: $agentEmail
      agentPhone: $agentPhone
      description: $description
      dwellingAmount: $dwellingAmount
      policyNumber: $policyNumber
      provider: $provider
      renewalDate: $renewalDate
    ) {
      insurancePolicy {
        agent
        agentEmail
        agentPhone
        provider
        renewalDate
        policyNumber
        dwellingAmount
        kind
        document {
          ... on Document {
            identifier
          }
        }
      }
    }
  }
`;

export const UPSERT_RENTAL_AGREEMENT = gql`
  mutation UpsertRentalAgreement(
    $trackId: IdentifierType!
    $rentalAmount: Decimal!
    $rentalAmountFrequency: PeriodicityEnum!
    $agreementExpirationDate: Date
    $uploadedDocIds: [IdentifierType!]
  ) {
    upsertRentalAgreement(
      trackId: $trackId
      rentalAmount: $rentalAmount
      rentalAmountFrequency: $rentalAmountFrequency
      agreementExpirationDate: $agreementExpirationDate
      uploadedDocIds: $uploadedDocIds
    ) {
      rentalAgreement {
        identifier
      }
    }
  }
`;

export const DELETE_RENTAL_AGREEMENT = gql`
  mutation DeleteRentalAgreement($rentalAgreementId: IdentifierType!) {
    deleteRentalAgreement(rentalAgreementId: $rentalAgreementId) {
      ok
    }
  }
`;

export const CREATE_CONDO_MASTER_POLICY = gql`
  mutation CreateCondoMasterPolicy(
    $trackId: IdentifierType!
    $uploadedDocIds: [IdentifierType!]!
    $agentName: String
    $agentEmail: String
    $agentPhone: String
    $provider: String!
    $replacementCost: Boolean!
    $renewalDate: Date!
    $policyNumber: String!
    $dwellingAmount: Int!
  ) {
    createCondoMasterPolicy(
      trackId: $trackId
      uploadedDocIds: $uploadedDocIds
      agentName: $agentName
      agentEmail: $agentEmail
      agentPhone: $agentPhone
      provider: $provider
      replacementCost: $replacementCost
      renewalDate: $renewalDate
      policyNumber: $policyNumber
      dwellingAmount: $dwellingAmount
    ) {
      insurancePolicy {
        agent
        agentEmail
        agentPhone
        provider
        replacementCost
        renewalDate
        policyNumber
        dwellingAmount
        kind
        document {
          ... on Document {
            identifier
          }
        }
      }
    }
  }
`;

export const UPDATE_CONDO_MASTER_POLICY = gql`
  mutation UpdateCondoMasterPolicy(
    $insurancePolicyId: IdentifierType!
    $uploadedDocIds: [IdentifierType!]
    $agentName: String
    $agentEmail: String
    $agentPhone: String
    $provider: String!
    $replacementCost: Boolean!
    $renewalDate: Date!
    $policyNumber: String!
    $dwellingAmount: Int!
    $notes: String
  ) {
    updateCondoMasterPolicy(
      insurancePolicyId: $insurancePolicyId
      uploadedDocIds: $uploadedDocIds
      agentName: $agentName
      agentEmail: $agentEmail
      agentPhone: $agentPhone
      provider: $provider
      replacementCost: $replacementCost
      renewalDate: $renewalDate
      policyNumber: $policyNumber
      dwellingAmount: $dwellingAmount
      notes: $notes
    ) {
      insurancePolicy {
        agent
        agentEmail
        agentPhone
        provider
        replacementCost
        renewalDate
        policyNumber
        dwellingAmount
        kind
        document {
          ... on Document {
            identifier
          }
        }
      }
    }
  }
`;

export const DELETE_INSURANCE_POLICY = gql`
  mutation DeleteInsurancePolicy($insurancePolicyId: IdentifierType!) {
    deleteInsurancePolicy(insurancePolicyId: $insurancePolicyId) {
      ok
    }
  }
`;

export const CREATE_MORTGAGE_FROM_TASK = gql`
  mutation CreateMortgageLien(
    $asOfDate: Date!
    $currentBalance: Decimal!
    $docIdsToLink: [IdentifierType!]!
    $escrowAmount: Decimal!
    $hasForbearance: Boolean!
    $holder: String!
    $interestAmount: Decimal!
    $isMoreThanOneMonthPastDue: Boolean!
    $mortgageAccountNumber: String!
    $notes: String
    $pastDueAmount: Decimal
    $pastDueFees: Decimal
    $principalAmount: Decimal!
    $trackId: IdentifierType!
    $hasAModification: Boolean
  ) {
    createMortgageFromTask(
      asOfDate: $asOfDate
      currentBalance: $currentBalance
      escrowAmount: $escrowAmount
      hasForbearance: $hasForbearance
      holder: $holder
      interestAmount: $interestAmount
      isMoreThanOneMonthPastDue: $isMoreThanOneMonthPastDue
      mortgageAccountNumber: $mortgageAccountNumber
      notes: $notes
      pastDueAmount: $pastDueAmount
      pastDueFees: $pastDueFees
      principalAmount: $principalAmount
      trackId: $trackId
      docIdsToLink: $docIdsToLink
      hasAModification: $hasAModification
    ) {
      mortgageLien {
        identifier
      }
    }
  }
`;

export const UPDATE_MORTGAGE_FROM_TASK = gql`
  mutation UpdateMortgageLien(
    $asOfDate: Date!
    $currentBalance: Decimal!
    $docIdsToLink: [IdentifierType!]!
    $escrowAmount: Decimal!
    $hasForbearance: Boolean!
    $holder: String!
    $interestAmount: Decimal!
    $isMoreThanOneMonthPastDue: Boolean!
    $lienId: IdentifierType!
    $mortgageAccountNumber: String!
    $notes: String
    $pastDueAmount: Decimal
    $pastDueFees: Decimal
    $principalAmount: Decimal!
    $hasAModification: Boolean
  ) {
    updateMortgageFromTask(
      asOfDate: $asOfDate
      currentBalance: $currentBalance
      escrowAmount: $escrowAmount
      hasForbearance: $hasForbearance
      holder: $holder
      interestAmount: $interestAmount
      isMoreThanOneMonthPastDue: $isMoreThanOneMonthPastDue
      mortgageAccountNumber: $mortgageAccountNumber
      notes: $notes
      pastDueAmount: $pastDueAmount
      pastDueFees: $pastDueFees
      principalAmount: $principalAmount
      lienId: $lienId
      docIdsToLink: $docIdsToLink
      hasAModification: $hasAModification
    ) {
      mortgageLien {
        identifier
      }
    }
  }
`;

export const CREATE_HELOC_LIEN = gql`
  mutation CreateHeloc(
    $maxLineAmount: Decimal!
    $asOfDate: Date!
    $currentBalance: Decimal!
    $docIdsToLink: [IdentifierType!]!
    $endOfTermDate: Date
    $escrowAmount: Decimal!
    $hasForbearance: Boolean!
    $holder: String!
    $interestAmount: Decimal!
    $isMoreThanOneMonthPastDue: Boolean!
    $lienDate: Date
    $mortgageAccountNumber: String!
    $mortgageInvestorKind: LienReviewMortgageInvestorKind!
    $notes: String
    $onPropertyReport: Boolean
    $originalAmount: Decimal
    $pastDueAmount: Decimal
    $pastDueFees: Decimal
    $position: Int
    $endOfDrawPeriodDate: Date!
    $principalAmount: Decimal!
    $rate: Decimal
    $rateType: LienReviewRateType
    $trackId: IdentifierType!
    $hasAModification: Boolean
  ) {
    createHeloc(
      maxLineAmount: $maxLineAmount
      asOfDate: $asOfDate
      currentBalance: $currentBalance
      endOfTermDate: $endOfTermDate
      escrowAmount: $escrowAmount
      hasForbearance: $hasForbearance
      holder: $holder
      interestAmount: $interestAmount
      isMoreThanOneMonthPastDue: $isMoreThanOneMonthPastDue
      lienDate: $lienDate
      mortgageAccountNumber: $mortgageAccountNumber
      mortgageInvestorKind: $mortgageInvestorKind
      notes: $notes
      onPropertyReport: $onPropertyReport
      originalAmount: $originalAmount
      pastDueAmount: $pastDueAmount
      pastDueFees: $pastDueFees
      position: $position
      endOfDrawPeriodDate: $endOfDrawPeriodDate
      principalAmount: $principalAmount
      rate: $rate
      rateType: $rateType
      trackId: $trackId
      docIdsToLink: $docIdsToLink
      hasAModification: $hasAModification
    ) {
      heloc {
        identifier
      }
    }
  }
`;

export const UPDATE_HELOC_LIEN = gql`
  mutation UpdateHeloc(
    $maxLineAmount: Decimal!
    $asOfDate: Date!
    $currentBalance: Decimal!
    $docIdsToLink: [IdentifierType!]!
    $endOfTermDate: Date
    $escrowAmount: Decimal!
    $hasForbearance: Boolean!
    $holder: String!
    $interestAmount: Decimal!
    $isMoreThanOneMonthPastDue: Boolean!
    $lienId: IdentifierType!
    $lienDate: Date
    $mortgageAccountNumber: String!
    $mortgageInvestorKind: LienReviewMortgageInvestorKind!
    $notes: String
    $onPropertyReport: Boolean
    $originalAmount: Decimal
    $pastDueAmount: Decimal
    $pastDueFees: Decimal
    $position: Int
    $endOfDrawPeriodDate: Date!
    $principalAmount: Decimal!
    $rate: Decimal
    $rateType: LienReviewRateType
    $hasAModification: Boolean
  ) {
    updateHeloc(
      maxLineAmount: $maxLineAmount
      asOfDate: $asOfDate
      currentBalance: $currentBalance
      endOfTermDate: $endOfTermDate
      escrowAmount: $escrowAmount
      hasForbearance: $hasForbearance
      holder: $holder
      interestAmount: $interestAmount
      isMoreThanOneMonthPastDue: $isMoreThanOneMonthPastDue
      lienDate: $lienDate
      mortgageAccountNumber: $mortgageAccountNumber
      mortgageInvestorKind: $mortgageInvestorKind
      notes: $notes
      onPropertyReport: $onPropertyReport
      originalAmount: $originalAmount
      pastDueAmount: $pastDueAmount
      pastDueFees: $pastDueFees
      position: $position
      endOfDrawPeriodDate: $endOfDrawPeriodDate
      principalAmount: $principalAmount
      rate: $rate
      rateType: $rateType
      lienId: $lienId
      docIdsToLink: $docIdsToLink
      hasAModification: $hasAModification
    ) {
      heloc {
        identifier
      }
    }
  }
`;

export const CREATE_HOME_EQUITY_LOAN = gql`
  mutation CreateHomeEquityLoan(
    $asOfDate: Date!
    $currentBalance: Decimal!
    $docIdsToLink: [IdentifierType!]!
    $endOfTermDate: Date
    $escrowAmount: Decimal!
    $hasForbearance: Boolean!
    $holder: String!
    $interestAmount: Decimal!
    $isMoreThanOneMonthPastDue: Boolean!
    $lienDate: Date
    $mortgageAccountNumber: String!
    $mortgageInvestorKind: LienReviewMortgageInvestorKind!
    $notes: String
    $onPropertyReport: Boolean
    $originalAmount: Decimal
    $pastDueAmount: Decimal
    $pastDueFees: Decimal
    $position: Int
    $principalAmount: Decimal!
    $rate: Decimal
    $rateType: LienReviewRateType
    $trackId: IdentifierType!
    $hasAModification: Boolean
  ) {
    createHomeEquityLoan(
      asOfDate: $asOfDate
      currentBalance: $currentBalance
      endOfTermDate: $endOfTermDate
      escrowAmount: $escrowAmount
      hasForbearance: $hasForbearance
      holder: $holder
      interestAmount: $interestAmount
      isMoreThanOneMonthPastDue: $isMoreThanOneMonthPastDue
      lienDate: $lienDate
      mortgageAccountNumber: $mortgageAccountNumber
      mortgageInvestorKind: $mortgageInvestorKind
      notes: $notes
      onPropertyReport: $onPropertyReport
      originalAmount: $originalAmount
      pastDueAmount: $pastDueAmount
      pastDueFees: $pastDueFees
      position: $position
      principalAmount: $principalAmount
      rate: $rate
      rateType: $rateType
      trackId: $trackId
      docIdsToLink: $docIdsToLink
      hasAModification: $hasAModification
    ) {
      homeEquityLoan {
        identifier
      }
    }
  }
`;

export const UPDATE_HOME_EQUITY_LOAN = gql`
  mutation UpdateHomeEquityLoan(
    $asOfDate: Date!
    $currentBalance: Decimal!
    $docIdsToLink: [IdentifierType!]!
    $endOfTermDate: Date
    $escrowAmount: Decimal!
    $hasForbearance: Boolean!
    $holder: String!
    $interestAmount: Decimal!
    $isMoreThanOneMonthPastDue: Boolean!
    $lienId: IdentifierType!
    $lienDate: Date
    $mortgageAccountNumber: String!
    $mortgageInvestorKind: LienReviewMortgageInvestorKind!
    $notes: String
    $onPropertyReport: Boolean
    $originalAmount: Decimal
    $pastDueAmount: Decimal
    $pastDueFees: Decimal
    $position: Int
    $principalAmount: Decimal!
    $rate: Decimal
    $rateType: LienReviewRateType
    $hasAModification: Boolean
  ) {
    updateHomeEquityLoan(
      asOfDate: $asOfDate
      currentBalance: $currentBalance
      endOfTermDate: $endOfTermDate
      escrowAmount: $escrowAmount
      hasForbearance: $hasForbearance
      holder: $holder
      interestAmount: $interestAmount
      isMoreThanOneMonthPastDue: $isMoreThanOneMonthPastDue
      lienDate: $lienDate
      mortgageAccountNumber: $mortgageAccountNumber
      mortgageInvestorKind: $mortgageInvestorKind
      notes: $notes
      onPropertyReport: $onPropertyReport
      originalAmount: $originalAmount
      pastDueAmount: $pastDueAmount
      pastDueFees: $pastDueFees
      position: $position
      principalAmount: $principalAmount
      rate: $rate
      rateType: $rateType
      lienId: $lienId
      docIdsToLink: $docIdsToLink
      hasAModification: $hasAModification
    ) {
      homeEquityLoan {
        identifier
      }
    }
  }
`;

export const CREATE_PRIVATE_MORTGAGE = gql`
  mutation CreatePrivateMortgage(
    $asOfDate: Date!
    $currentBalance: Decimal!
    $docIdsToLink: [IdentifierType!]!
    $endOfTermDate: Date
    $escrowAmount: Decimal!
    $hasForbearance: Boolean!
    $holder: String!
    $interestAmount: Decimal!
    $wasProfessionallyServiced: Boolean!
    $isMoreThanOneMonthPastDue: Boolean!
    $lienDate: Date
    $mortgageAccountNumber: String!
    $mortgageInvestorKind: LienReviewMortgageInvestorKind!
    $notes: String
    $onPropertyReport: Boolean
    $originalAmount: Decimal
    $pastDueAmount: Decimal
    $pastDueFees: Decimal
    $position: Int
    $principalAmount: Decimal!
    $rate: Decimal
    $rateType: LienReviewRateType
    $trackId: IdentifierType!
    $hasAModification: Boolean
  ) {
    createPrivateMortgage(
      asOfDate: $asOfDate
      currentBalance: $currentBalance
      endOfTermDate: $endOfTermDate
      escrowAmount: $escrowAmount
      hasForbearance: $hasForbearance
      holder: $holder
      interestAmount: $interestAmount
      wasProfessionallyServiced: $wasProfessionallyServiced
      isMoreThanOneMonthPastDue: $isMoreThanOneMonthPastDue
      lienDate: $lienDate
      mortgageAccountNumber: $mortgageAccountNumber
      mortgageInvestorKind: $mortgageInvestorKind
      notes: $notes
      onPropertyReport: $onPropertyReport
      originalAmount: $originalAmount
      pastDueAmount: $pastDueAmount
      pastDueFees: $pastDueFees
      position: $position
      principalAmount: $principalAmount
      rate: $rate
      rateType: $rateType
      trackId: $trackId
      docIdsToLink: $docIdsToLink
      hasAModification: $hasAModification
    ) {
      privateMortgage {
        identifier
      }
    }
  }
`;

export const UPDATE_PRIVATE_MORTGAGE = gql`
  mutation UpdatePrivateMortgage(
    $asOfDate: Date!
    $currentBalance: Decimal!
    $docIdsToLink: [IdentifierType!]!
    $endOfTermDate: Date
    $escrowAmount: Decimal!
    $hasForbearance: Boolean!
    $holder: String!
    $interestAmount: Decimal!
    $wasProfessionallyServiced: Boolean!
    $isMoreThanOneMonthPastDue: Boolean!
    $lienId: IdentifierType!
    $lienDate: Date
    $mortgageAccountNumber: String!
    $mortgageInvestorKind: LienReviewMortgageInvestorKind!
    $notes: String
    $onPropertyReport: Boolean
    $originalAmount: Decimal
    $pastDueAmount: Decimal
    $pastDueFees: Decimal
    $position: Int
    $principalAmount: Decimal!
    $rate: Decimal
    $rateType: LienReviewRateType
    $hasAModification: Boolean
  ) {
    updatePrivateMortgage(
      asOfDate: $asOfDate
      currentBalance: $currentBalance
      endOfTermDate: $endOfTermDate
      escrowAmount: $escrowAmount
      hasForbearance: $hasForbearance
      holder: $holder
      interestAmount: $interestAmount
      wasProfessionallyServiced: $wasProfessionallyServiced
      isMoreThanOneMonthPastDue: $isMoreThanOneMonthPastDue
      lienDate: $lienDate
      mortgageAccountNumber: $mortgageAccountNumber
      mortgageInvestorKind: $mortgageInvestorKind
      notes: $notes
      onPropertyReport: $onPropertyReport
      originalAmount: $originalAmount
      pastDueAmount: $pastDueAmount
      pastDueFees: $pastDueFees
      position: $position
      principalAmount: $principalAmount
      rate: $rate
      rateType: $rateType
      lienId: $lienId
      docIdsToLink: $docIdsToLink
      hasAModification: $hasAModification
    ) {
      privateMortgage {
        identifier
      }
    }
  }
`;

export const CREATE_TAX_LIEN = gql`
  mutation CreateTaxLien(
    $holder: String
    $lienDate: Date
    $notes: String
    $onPropertyReport: Boolean
    $originalAmount: Decimal
    $position: Int
    $trackId: IdentifierType!
  ) {
    createTaxLien(
      holder: $holder
      lienDate: $lienDate
      notes: $notes
      onPropertyReport: $onPropertyReport
      originalAmount: $originalAmount
      position: $position
      trackId: $trackId
    ) {
      taxLien {
        identifier
      }
    }
  }
`;

export const UPDATE_TAX_LIEN = gql`
  mutation UpdateTaxLien(
    $holder: String
    $lienDate: Date
    $notes: String
    $onPropertyReport: Boolean
    $originalAmount: Decimal
    $position: Int
    $lienId: IdentifierType!
  ) {
    updateTaxLien(
      holder: $holder
      lienDate: $lienDate
      notes: $notes
      onPropertyReport: $onPropertyReport
      originalAmount: $originalAmount
      position: $position
      lienId: $lienId
    ) {
      taxLien {
        identifier
      }
    }
  }
`;

export const CREATE_OTHER_LIEN = gql`
  mutation CreateOtherLien(
    $lienKind: OtherLienReviewKind!
    $asOfDate: Date
    $currentBalance: Decimal!
    $endOfTermDate: Date
    $escrowAmount: Decimal
    $holder: String
    $lender: String
    $interestAmount: Decimal
    $isMoreThanOneMonthPastDue: Boolean
    $lienDate: Date
    $notes: String
    $onPropertyReport: Boolean
    $originalAmount: Decimal
    $pastDueAmount: Decimal
    $pastDueFees: Decimal
    $position: Int
    $principalAmount: Decimal
    $rate: Decimal
    $rateType: LienReviewRateType
    $trackId: IdentifierType!
  ) {
    createOtherLien(
      lienKind: $lienKind
      asOfDate: $asOfDate
      currentBalance: $currentBalance
      endOfTermDate: $endOfTermDate
      escrowAmount: $escrowAmount
      holder: $holder
      lender: $lender
      interestAmount: $interestAmount
      isMoreThanOneMonthPastDue: $isMoreThanOneMonthPastDue
      lienDate: $lienDate
      notes: $notes
      onPropertyReport: $onPropertyReport
      originalAmount: $originalAmount
      pastDueAmount: $pastDueAmount
      pastDueFees: $pastDueFees
      position: $position
      principalAmount: $principalAmount
      rate: $rate
      rateType: $rateType
      trackId: $trackId
    ) {
      otherLien {
        identifier
      }
    }
  }
`;

export const UPDATE_OTHER_LIEN = gql`
  mutation UpdateOtherLien(
    $lienKind: OtherLienReviewKind!
    $asOfDate: Date
    $currentBalance: Decimal!
    $endOfTermDate: Date
    $escrowAmount: Decimal
    $holder: String
    $lender: String
    $interestAmount: Decimal
    $isMoreThanOneMonthPastDue: Boolean
    $lienId: IdentifierType!
    $lienDate: Date
    $notes: String
    $onPropertyReport: Boolean
    $originalAmount: Decimal
    $pastDueAmount: Decimal
    $pastDueFees: Decimal
    $position: Int
    $principalAmount: Decimal
    $rate: Decimal
    $rateType: LienReviewRateType
  ) {
    updateOtherLien(
      lienKind: $lienKind
      asOfDate: $asOfDate
      currentBalance: $currentBalance
      endOfTermDate: $endOfTermDate
      escrowAmount: $escrowAmount
      holder: $holder
      lender: $lender
      interestAmount: $interestAmount
      isMoreThanOneMonthPastDue: $isMoreThanOneMonthPastDue
      lienDate: $lienDate
      notes: $notes
      onPropertyReport: $onPropertyReport
      originalAmount: $originalAmount
      pastDueAmount: $pastDueAmount
      pastDueFees: $pastDueFees
      position: $position
      principalAmount: $principalAmount
      rate: $rate
      rateType: $rateType
      lienId: $lienId
    ) {
      otherLien {
        identifier
      }
    }
  }
`;

export const CREATE_JUDGEMENT_LIEN = gql`
  mutation CreateJudgementLien(
    $holder: String
    $lienDate: Date
    $notes: String
    $onPropertyReport: Boolean
    $originalAmount: Decimal
    $position: Int
    $trackId: IdentifierType!
  ) {
    createJudgementLien(
      holder: $holder
      lienDate: $lienDate
      notes: $notes
      onPropertyReport: $onPropertyReport
      originalAmount: $originalAmount
      position: $position
      trackId: $trackId
    ) {
      judgementLien {
        identifier
      }
    }
  }
`;

export const UPDATE_JUDGEMENT_LIEN = gql`
  mutation UpdateJudgementLien(
    $holder: String
    $lienDate: Date
    $lienId: IdentifierType!
    $notes: String
    $onPropertyReport: Boolean
    $originalAmount: Decimal
    $position: Int
  ) {
    updateJudgementLien(
      holder: $holder
      lienDate: $lienDate
      lienId: $lienId
      notes: $notes
      onPropertyReport: $onPropertyReport
      originalAmount: $originalAmount
      position: $position
    ) {
      judgementLien {
        identifier
      }
    }
  }
`;

export const CREATE_HOME_EQUITY_INVESTMENT_LIEN = gql`
  mutation CreateHomeEquityInvestment(
    $holder: String
    $lienDate: Date
    $endOfTermDate: Date
    $notes: String
    $onPropertyReport: Boolean
    $originalAmount: Decimal
    $position: Int
    $trackId: IdentifierType!
  ) {
    createHomeEquityInvestment(
      holder: $holder
      lienDate: $lienDate
      endOfTermDate: $endOfTermDate
      notes: $notes
      onPropertyReport: $onPropertyReport
      originalAmount: $originalAmount
      position: $position
      trackId: $trackId
    ) {
      homeEquityInvestment {
        identifier
      }
    }
  }
`;

export const UPDATE_HOME_EQUITY_INVESTMENT_LIEN = gql`
  mutation UpdateHomeEquityInvestment(
    $holder: String
    $lienDate: Date
    $endOfTermDate: Date
    $lienId: IdentifierType!
    $notes: String
    $onPropertyReport: Boolean
    $originalAmount: Decimal
    $position: Int
  ) {
    updateHomeEquityInvestment(
      holder: $holder
      lienDate: $lienDate
      endOfTermDate: $endOfTermDate
      lienId: $lienId
      notes: $notes
      onPropertyReport: $onPropertyReport
      originalAmount: $originalAmount
      position: $position
    ) {
      homeEquityInvestment {
        identifier
      }
    }
  }
`;

export const CREATE_PARTIAL_CLAIM_LIEN = gql`
  mutation CreatePartialClaimLien(
    $endOfTermDate: Date
    $lender: String
    $lienDate: Date
    $notes: String
    $onPropertyReport: Boolean
    $originalAmount: Decimal
    $position: Int
    $trackId: IdentifierType!
  ) {
    createPartialClaimLien(
      endOfTermDate: $endOfTermDate
      lender: $lender
      lienDate: $lienDate
      notes: $notes
      onPropertyReport: $onPropertyReport
      originalAmount: $originalAmount
      position: $position
      trackId: $trackId
    ) {
      partialClaimLien {
        identifier
      }
    }
  }
`;

export const UPDATE_PARTIAL_CLAIM_LIEN = gql`
  mutation UpdatePartialClaimLien(
    $endOfTermDate: Date
    $lender: String
    $lienDate: Date
    $lienId: IdentifierType!
    $notes: String
    $onPropertyReport: Boolean
    $originalAmount: Decimal
    $position: Int
  ) {
    updatePartialClaimLien(
      endOfTermDate: $endOfTermDate
      lender: $lender
      lienDate: $lienDate
      lienId: $lienId
      notes: $notes
      onPropertyReport: $onPropertyReport
      originalAmount: $originalAmount
      position: $position
    ) {
      partialClaimLien {
        identifier
      }
    }
  }
`;

export const DISQUALIFY_NEW_PAST_DUE_LIEN = gql`
  mutation DisqualifyNewPastDueLien(
    $lienKind: LienReviewKind!
    $trackId: IdentifierType!
    $onPropertyReport: Boolean
    $position: Int
    $originalAmount: Decimal
    $lienDate: Date
    $endOfTermDate: Date
    $rate: Decimal
    $rateType: LienReviewRateType
    $wasProfessionallyServiced: Boolean
    $docIdsToLink: [IdentifierType!]
  ) {
    disqualifyNewPastDueLien(
      trackId: $trackId
      lienKind: $lienKind
      onPropertyReport: $onPropertyReport
      position: $position
      originalAmount: $originalAmount
      lienDate: $lienDate
      endOfTermDate: $endOfTermDate
      rate: $rate
      rateType: $rateType
      wasProfessionallyServiced: $wasProfessionallyServiced
      docIdsToLink: $docIdsToLink
    ) {
      lien {
        identifier
      }
    }
  }
`;

export const DISQUALIFY_EXISTING_PAST_DUE_LIEN = gql`
  mutation DisqualifyExistingPastDueLien(
    $lienId: IdentifierType!
    $lienKind: LienReviewKind!
    $onPropertyReport: Boolean
    $position: Int
    $originalAmount: Decimal
    $lienDate: Date
    $endOfTermDate: Date
    $rate: Decimal
    $rateType: LienReviewRateType
    $wasProfessionallyServiced: Boolean
    $docIdsToLink: [IdentifierType!]
  ) {
    disqualifyExistingPastDueLien(
      lienKind: $lienKind
      lienId: $lienId
      onPropertyReport: $onPropertyReport
      position: $position
      originalAmount: $originalAmount
      lienDate: $lienDate
      endOfTermDate: $endOfTermDate
      rate: $rate
      rateType: $rateType
      wasProfessionallyServiced: $wasProfessionallyServiced
      docIdsToLink: $docIdsToLink
    ) {
      lien {
        identifier
      }
    }
  }
`;

export const CREATE_UCC_LIEN = gql`
  mutation CreateUccLien(
    $holder: String
    $lienDate: Date
    $notes: String
    $isFixture: Boolean
    $onPropertyReport: Boolean
    $position: Int
    $trackId: IdentifierType!
  ) {
    createUccLien(
      holder: $holder
      lienDate: $lienDate
      notes: $notes
      isFixture: $isFixture
      onPropertyReport: $onPropertyReport
      position: $position
      trackId: $trackId
    ) {
      uccLien {
        identifier
      }
    }
  }
`;

export const UPDATE_UCC_LIEN = gql`
  mutation UpdateUCCLien(
    $lienId: IdentifierType!
    $holder: String
    $lienDate: Date
    $notes: String
    $isFixture: Boolean
    $onPropertyReport: Boolean
    $position: Int
  ) {
    updateUccLien(
      holder: $holder
      lienDate: $lienDate
      lienId: $lienId
      notes: $notes
      isFixture: $isFixture
      onPropertyReport: $onPropertyReport
      position: $position
    ) {
      uccLien {
        identifier
      }
    }
  }
`;

export const DELETE_LIEN = gql`
  mutation DeleteLien($lienId: IdentifierType!) {
    deleteLien(lienId: $lienId) {
      ok
    }
  }
`;

export const CREATE_PAYDOWN = gql`
  mutation CreatePaydown(
    $lienId: IdentifierType!
    $principalPaydownAmount: Decimal
    $feePaydownAmount: Decimal
    $goodThroughDate: Date
    $perDiem: Decimal
    $accountNumber: String
    $isPayoff: Boolean!
    $paymentMethod: PaymentMethod!
    $mailingInstructions: String
    $routingNumber: String
  ) {
    createPaydown(
      lienId: $lienId
      principalPaydownAmount: $principalPaydownAmount
      feePaydownAmount: $feePaydownAmount
      goodThroughDate: $goodThroughDate
      perDiem: $perDiem
      accountNumber: $accountNumber
      isPayoff: $isPayoff
      paymentMethod: $paymentMethod
      mailingInstructions: $mailingInstructions
      routingNumber: $routingNumber
    ) {
      paydown {
        identifier
      }
    }
  }
`;

export const UPDATE_PAYDOWN = gql`
  mutation UpdatePaydown(
    $paydownId: IdentifierType!
    $principalPaydownAmount: Decimal
    $feePaydownAmount: Decimal
    $goodThroughDate: Date
    $perDiem: Decimal
    $accountNumber: String
    $isPayoff: Boolean!
    $paymentMethod: PaymentMethod!
    $mailingInstructions: String
    $routingNumber: String
  ) {
    updatePaydown(
      paydownId: $paydownId
      principalPaydownAmount: $principalPaydownAmount
      feePaydownAmount: $feePaydownAmount
      goodThroughDate: $goodThroughDate
      perDiem: $perDiem
      accountNumber: $accountNumber
      isPayoff: $isPayoff
      paymentMethod: $paymentMethod
      mailingInstructions: $mailingInstructions
      routingNumber: $routingNumber
    ) {
      paydown {
        identifier
      }
    }
  }
`;

export const DELETE_PAYDOWN = gql`
  mutation DeletePaydown($paydownId: IdentifierType!) {
    deletePaydown(paydownId: $paydownId) {
      ok
    }
  }
`;

export const POLICY_KIND_TO_MUTATION = {
  [INSURANCE_POLICY_KINDS.HOMEOWNERS]: UPDATE_HOMEOWNERS_INSURANCE_POLICY,
  [INSURANCE_POLICY_KINDS.COOP]: UPDATE_HOMEOWNERS_COOP_INSURANCE_POLICY,
  [INSURANCE_POLICY_KINDS.CONDO]: UPDATE_CONDO_MASTER_POLICY,
  [INSURANCE_POLICY_KINDS.FLOOD]: UPDATE_FLOOD_INSURANCE_POLICY,
  [INSURANCE_POLICY_KINDS.OTHER]: UPDATE_OTHER_INSURANCE_POLICY,
};

export const UPSERT_HOA = gql`
  mutation UpsertHoa(
    $hoaName: String
    $hoaFeeAmount: Decimal
    $hoaFeeFrequency: PeriodicityEnum
    $trackId: IdentifierType!
    $uploadedDocIds: [IdentifierType!]
  ) {
    upsertHoa(
      trackId: $trackId
      hoaName: $hoaName
      hoaFeeAmount: $hoaFeeAmount
      hoaFeeFrequency: $hoaFeeFrequency
      uploadedDocIds: $uploadedDocIds
    ) {
      hoa {
        identifier
      }
    }
  }
`;

export const DELETE_HOA = gql`
  mutation DeleteHoa($hoaId: IdentifierType!) {
    deleteHoa(hoaId: $hoaId) {
      ok
    }
  }
`;

export const PROPERTY_TAX_UPSERT_MUTATION = gql`
  mutation UpsertPropertyTax($trackId: IdentifierType!, $totalAnnualTaxAmount: Decimal!) {
    upsertPropertyTax(trackId: $trackId, totalAnnualTaxAmount: $totalAnnualTaxAmount) {
      propertyTax {
        identifier
        totalAnnualTaxAmount
      }
    }
  }
`;

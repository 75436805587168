import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import env from 'utils/env';
import {
  Icon,
  Button,
  IconButton,
  Paper,
  DataTable,
  MenuDropdown,
  MenuDropdownItem,
  ModalConfirm,
} from '@hometap/htco-components';
import ReggoraStatus from '../ApplicationReview/components/ReggoraStatus/ReggoraStatus';
import AppraisalOrderSlider from './components/SliderForms/AppraisalOrderSlider';
import {
  getReggoraLink,
  REGGORA_STATUS_LABELS,
  PRODUCT_NUMBERS_TRADITIONAL,
  extractFormNumber,
} from '../utils/reggora';
import useAppraisalsData from './hooks/useAppraisalsData';
import { formatDayOfWeekMonthDayYear } from '../../../utils/dates';
import useCurrentUser from 'hooks/useCurrentUser';
import RevisionRequestSlider from './components/SliderForms/RevisionRequestSlider';
import TraditionalAppraisalHomeValSlider from './components/SliderForms/TraditionalAppraisalHomeValSlider';

import './Appraisals.scss';
import { gql, useQuery } from '@apollo/client';

const URL_PARAMS_ACTIONS = {
  PHYSICAL_APPRAISAL_SLIDER_OPEN: 'physicalAppraisalOpen',
};

const modalConfirmDefaultOptions = {
  open: false,
  onClose: () => {},
  isDisabledConfirmButton: false,
  header: '',
  children: null,
  onConfirm: () => {},
  onCancel: () => {},
};

// Used to resolved a trackId to a friendly ID in order to determine if the associated appraisal's loan ID
// is associated with the currently viewed track
const GET_TRACK = gql`
  query GetTrack($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      id
      friendlyId
    }
  }
`;

const AppraisalsController = () => {
  const { isInApplicationSpecialistGroup } = useCurrentUser();
  const { trackId } = useParams();
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [isAppraisalOrderSliderOpen, setIsAppraisalOrderSliderOpen] = useState(false);
  const [isRevisionRequestSliderOpen, setIsRevisionRequestSliderOpen] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const open = urlParams.get(URL_PARAMS_ACTIONS.PHYSICAL_APPRAISAL_SLIDER_OPEN)?.toLowerCase() === 'true' ?? false;
  const [isPhysicalAppraisalSliderOpen, setIsPhysicalAppraisalSliderOpen] = useState(open);
  const [cancelOrderModalOptions, setCancelOrderModalOptions] = useState(modalConfirmDefaultOptions);

  // information about track's appraisals
  const {
    formattedAppraisalData,
    getReggoraOrderData,
    manuallyRefreshAppraisals,
    isDataTableLoading,
    refreshedReggoraDataLoading,
    cancelOrder,
    cancelOrderLoading,
    setAppraisalOfRecord,
  } = useAppraisalsData(trackId);
  const { data: trackData, loading: trackLoading } = useQuery(GET_TRACK, { variables: { trackId } });

  const loading = trackLoading || isDataTableLoading;

  const handleSetAppraisalOfRecord = (orderId, trackId) => {
    setAppraisalOfRecord(orderId, trackId).then(() => {
      getReggoraOrderData();
    });
  };

  const determineIfAppraisalIsExpired = row => {
    // NOTE: TOT-490 handles expiration logic
    return false;
  };

  const handleCancelOrderIntent = (orderId, orderProduct) => {
    setCancelOrderModalOptions({
      open: true,
      header: 'Cancel appraisal order',
      onClose: () => setCancelOrderModalOptions(modalConfirmDefaultOptions),
      children: (
        <div className="flex flex-col gap-8">
          <div>
            You are about to cancel appraisal order <strong>{orderProduct}</strong>. This cannot be undone.
          </div>
          <div>Are you sure you want to proceed?</div>
        </div>
      ),
      onConfirm: () => {
        cancelOrder(orderId, orderProduct).then(() => {
          // if canceling is successful, refresh the data and close the modal
          getReggoraOrderData();
          setCancelOrderModalOptions(modalConfirmDefaultOptions);
        });
      },
      onCancel: () => setCancelOrderModalOptions(modalConfirmDefaultOptions),
      confirmText: 'Yes, cancel order',
      cancelText: 'No, go back',
      theme: 'danger',
    });
  };

  const AppraisalMenuDropdown = ({ row }) => {
    const [isAppraisalMenuOpen, setIsAppraisalMenuOpen] = useState(false);

    const isNotCancellable =
      row?.orderStatus === REGGORA_STATUS_LABELS.SUBMITTED ||
      row?.orderStatus === REGGORA_STATUS_LABELS.REVISION_SUBMITTED ||
      row?.orderStatus === REGGORA_STATUS_LABELS.REVISION_REQUESTED;

    const isTraditionalProduct = PRODUCT_NUMBERS_TRADITIONAL.includes(extractFormNumber(row?.product));

    const isCancelled =
      row?.orderStatus === REGGORA_STATUS_LABELS.TYPO_CANCELLED || row?.orderStatus === REGGORA_STATUS_LABELS.CANCELLED;

    const isExpired = determineIfAppraisalIsExpired(row);

    const showCreateFollowupOrder = row?.isPrimary;
    const showCreateHomeValuation = isTraditionalProduct && isNotCancellable;
    const showRequestRevision = isNotCancellable;
    const showCancelOrder = !isNotCancellable && !isCancelled;
    const showSetAppraisalOfRecord = !isCancelled && !row?.appraisalOfRecord;

    const hasMenuItems =
      showCreateFollowupOrder ||
      showCreateHomeValuation ||
      showRequestRevision ||
      showCancelOrder ||
      showSetAppraisalOfRecord;

    return (
      <MenuDropdown
        disabled={loading || isExpired}
        isMenuOpen={isAppraisalMenuOpen}
        onMenuChange={setIsAppraisalMenuOpen}
        // Instead of not rendering the menu, we can just make it invisible to keep the layout consistent
        style={{ visibility: hasMenuItems && !isExpired ? 'visible' : 'hidden' }}
      >
        {showCreateFollowupOrder && (
          <MenuDropdownItem
            data-id="appraisals-controller-create-follow-up-order"
            aria-label="Create follow-up order"
            onClick={() => {
              setSelectedOrderId(row?.orderId);
              setIsAppraisalOrderSliderOpen(true);
            }}
          >
            Create follow-up order
          </MenuDropdownItem>
        )}
        {showCreateHomeValuation && (
          <MenuDropdownItem
            data-id="appraisals-controller-create-home-valuation"
            aria-label="Create home valuation"
            onClick={() => {
              setSelectedOrderId(row?.orderId);
              handleChangePhysicalAppraisalSlider(true);
            }}
          >
            Create home valuation
          </MenuDropdownItem>
        )}
        {showRequestRevision && (
          <MenuDropdownItem
            data-id="appraisals-controller-request-revision"
            aria-label="Request revision"
            onClick={() => {
              setSelectedOrderId(row?.orderId);
              setIsRevisionRequestSliderOpen(true);
            }}
          >
            Request revision
          </MenuDropdownItem>
        )}
        {showCancelOrder && (
          <MenuDropdownItem
            onClick={() => {
              handleCancelOrderIntent(row?.orderId, row?.product);
            }}
            aria-label="Cancel order"
            data-id="appraisals-controller-cancel-order"
          >
            Cancel order
          </MenuDropdownItem>
        )}
        {showSetAppraisalOfRecord && (
          <MenuDropdownItem
            onClick={() => {
              handleSetAppraisalOfRecord(row?.identifier, trackId);
              setIsAppraisalMenuOpen(!isAppraisalMenuOpen);
            }}
            aria-label="Set as appraisal of record"
            data-id="appraisals-controller-set-appraisal-of-record"
          >
            Use for underwriting
          </MenuDropdownItem>
        )}
      </MenuDropdown>
    );
  };

  // Data shown in the table in the Appraisals tab
  const reggoraOrderSelectors = [
    {
      id: 'appraisalType',
      name: <strong className="AppraisalsReggoraHeader">Appraisal type</strong>,
      selector: row => row?.appraisalType,
      cell: row => (
        <div
          className="AppraisalsTypeInfo"
          style={{
            opacity: determineIfAppraisalIsExpired(row) ? 0.5 : 1,
          }}
        >
          <strong>{row?.product}</strong>
          {/* if the loan number does not match the current track friendly id, link to that other track */}
          <div>
            {row?.loanNumber === trackData?.track?.friendlyId ? (
              row?.loanNumber
            ) : (
              <a href={`/tracks/${trackId}/application-review`}>{row?.loanNumber}</a>
            )}
          </div>
        </div>
      ),
    },
    {
      id: 'orderCreatedDatetime',
      name: <strong className="AppraisalsReggoraHeader">Ordered on</strong>,
      selector: row => row?.orderCreatedDatetime,
      // Traditional appraisals will display Scheduled Date, while virtual will not
      cell: row => (
        <span
          className="flex flex-col"
          style={{
            opacity: determineIfAppraisalIsExpired(row) ? 0.5 : 1,
          }}
        >
          <div className="AppraisalsTableText">
            {row?.orderCreatedDatetime && formatDayOfWeekMonthDayYear(row?.orderCreatedDatetime)}
          </div>
          <div className="AppraisalsTableSmallText">
            {row?.createdBy?.user?.firstName} {row?.createdBy?.user?.lastName}
          </div>
        </span>
      ),
    },
    {
      id: 'inspectionDatetime',
      name: <strong className="AppraisalsReggoraHeader">Scheduled for</strong>,
      selector: row => row?.inspectionDatetime,
      // Traditional appraisals will display Scheduled Date, while virtual will not
      cell: row => (
        <span
          className="AppraisalsTableText"
          style={{
            opacity: determineIfAppraisalIsExpired(row) ? 0.5 : 1,
          }}
        >
          {row?.inspectionDatetime && formatDayOfWeekMonthDayYear(row?.inspectionDatetime)}
        </span>
      ),
    },
    // NOTE: TOT-490 will implement effective date datatable column
    // {
    //   id: 'effectiveDatetime',
    //   name: <strong className="AppraisalsReggoraHeader">Effective date</strong>,
    //   selector: row => row?.effectiveDatetime,
    //   cell: row => (
    //     <span className="AppraisalsTableText" style={{
    //       opacity: determineIfAppraisalIsExpired(row) ? 0.5 : 1
    //     }}>
    //       {row?.effectiveDatetime && formatDayOfWeekMonthDayYear(row?.effectiveDatetime)}
    //     </span>
    //   ),
    // },
    {
      id: 'orderStatus',
      name: <strong className="AppraisalsReggoraHeader">Status</strong>,
      selector: row => row?.orderStatus,
      cell: row => (
        <div className="AppraisalsStatusWrapper">
          <ReggoraStatus label={row?.orderStatus} />
        </div>
      ),
    },
    {
      id: 'links',
      name: <strong className="AppraisalsReggoraHeader"></strong>,
      selector: row => row?.createdBy?.user?.friendlyId,
      cell: row => {
        return (
          <div className="AppraisalsLinksWrapper">
            {row?.appraisalOfRecord && <Icon name="icon-star-solid" className="AppraisalOfRecordIcon" />}
            {row?.document && (
              <IconButton
                theme="primary"
                icon="icon-file"
                onClick={() => {
                  window.open(`/tracks/${trackId}/documents/${row?.document?.identifier}`, '_blank');
                }}
              />
            )}
            <IconButton
              theme="primary"
              icon="arrow-square-up-right"
              onClick={() => {
                window.open(getReggoraLink(row?.orderId, env.isProd()), '_blank');
              }}
            />
            <AppraisalMenuDropdown row={row} />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    // Function to handle URL parameter changes
    const handleUrlParams = () => {
      const url = new URL(window.location.href);
      const isOpenParam = url.searchParams.get(URL_PARAMS_ACTIONS.PHYSICAL_APPRAISAL_SLIDER_OPEN);
      const isOpen = isOpenParam === 'true';

      // Update component state based on the URL parameter
      setIsPhysicalAppraisalSliderOpen(isOpen);
    };

    // Call the function on component mount
    handleUrlParams();

    // Add event listener to handle URL parameter changes
    window.addEventListener('popstate', handleUrlParams);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('popstate', handleUrlParams);
    };
  }, []);

  /**
   * @description syncs url params with the state of the physical appraisal slider
   * @param {boolean} state
   */
  const handleChangePhysicalAppraisalSlider = state => {
    const url = new URL(window.location.href);
    // remove the param from the url if the state is false
    if (!state) {
      url.searchParams.delete(URL_PARAMS_ACTIONS.PHYSICAL_APPRAISAL_SLIDER_OPEN);
    } else {
      url.searchParams.set(URL_PARAMS_ACTIONS.PHYSICAL_APPRAISAL_SLIDER_OPEN, state.toString());
    }
    window.history.pushState({}, '', url);

    setIsPhysicalAppraisalSliderOpen(state);
  };

  const reggoraOrderMobileSelectors = [...reggoraOrderSelectors.slice(0, 2), reggoraOrderSelectors.slice(-1)[0]];

  // custom styles for the cells in the Reggora table
  const customStyles = {
    cells: {
      style: {
        outlined: true,
      },
    },
    headCells: {
      style: {
        paddingTop: '12px',
        backgroundColor: '#F6F6F6',
      },
    },
    table: {
      style: {
        minHeight: '20px !important',
        overflow: 'visible',
      },
    },
    responsiveWrapper: {
      style: {
        // visible to allow menu popout to escape the table boundary
        overflow: 'visible',
      },
    },
  };

  return (
    <div className="AppraisalsControllerPage">
      <AppraisalOrderSlider
        orderId={selectedOrderId}
        isOpen={isAppraisalOrderSliderOpen}
        setIsOpen={setIsAppraisalOrderSliderOpen}
        refetch={getReggoraOrderData}
        trackId={trackId}
      />
      <RevisionRequestSlider
        orderId={selectedOrderId}
        trackId={trackId}
        isOpen={isRevisionRequestSliderOpen}
        setIsOpen={setIsRevisionRequestSliderOpen}
        refetch={getReggoraOrderData}
      />
      <TraditionalAppraisalHomeValSlider
        orderId={selectedOrderId}
        isOpen={isPhysicalAppraisalSliderOpen}
        trackId={trackId}
        onClose={() => {
          handleChangePhysicalAppraisalSlider(false);
        }}
      />
      <ModalConfirm
        isDisabledConfirmButton={cancelOrderLoading}
        data-id="appraisals-controller-cancel-order-modal"
        aria-label="Cancel order modal"
        {...cancelOrderModalOptions}
      />

      <Paper pad={0} className="AppraisalsControllerPaper">
        <div className="AppraisalsTableContainer">
          <div className="AppraisalsReggoraContainer">
            <div className="AppraisalsReggoraTableHeader">
              <h3 className="AppraisalsReggoraTitle">Appraisals</h3>
              <div>
                <Button
                  theme="link"
                  size="small"
                  data-id="appraisals-controller-refresh-appraisals"
                  aria-label="refresh appraisals"
                  loading={refreshedReggoraDataLoading}
                  onClick={() => {
                    manuallyRefreshAppraisals();
                  }}
                >
                  <span>
                    <Icon name="icon-refresh" /> Refresh data
                  </span>
                </Button>
                {isInApplicationSpecialistGroup && (
                  <Button
                    className="AppraisalsNewAppraisalButton"
                    size="small"
                    onClick={() => {
                      setSelectedOrderId(null);
                      setIsAppraisalOrderSliderOpen(true);
                    }}
                  >
                    <div className="AppraisalsNewAppraisalButtonInfo">
                      <Icon name="plus" />
                      <div className="AppraisalsNewAppraisalButtonText">New appraisal</div>
                    </div>
                  </Button>
                )}
              </div>
            </div>
            <hr className="AppraisalsSeparator" />
            <DataTable
              data={formattedAppraisalData ?? []}
              columns={reggoraOrderSelectors}
              customStyles={customStyles}
              loading={loading}
              // If there's no orders yet, prompt the user to create one in the table
              noDataComponent={
                <div className="AppraisalsNoOrders">
                  <div className="AppraisalsIconBackground">
                    <Icon name="home-smile" className="AppraisalsHomeSmile"></Icon>
                  </div>
                  <h3 className="AppraisalsNewAppraisalTitle">Start by adding a new appraisal</h3>
                  <span className="AppraisalsNoOrdersText">Any appraisals for this property will live here.</span>
                </div>
              }
              // Mobile view setup
              mobileColumns={reggoraOrderMobileSelectors}
              respondAt="md"
            />
          </div>
        </div>
      </Paper>

      <div className="AppraisalsCallOut">
        <span>
          Use this if you need to create a home valuation using a traditional appraisal that is not in the above table.
        </span>

        <Button
          theme="link"
          data-id="appraisals-controller-create-traditional-appraisal"
          aria-label="create home valuation"
          onClick={() => {
            setSelectedOrderId(null);
            handleChangePhysicalAppraisalSlider(true);
          }}
        >
          Create home valuation
        </Button>
      </div>
    </div>
  );
};

export default AppraisalsController;

import { CurrencyInput, DatePicker, MuiSelect, MuiTextInput } from '@hometap/htco-components';
import React, { useMemo } from 'react';
import { getRateTypeOptions } from 'apps/track-details/utils/taskForms';
import useConfigurations from 'hooks/useConfigurations';
import { getLienPropertyReportFieldsSettings } from '../utils/liens';
import useCurrentUser from 'hooks/useCurrentUser';
import {
  REQUIRED_ERROR_MESSAGE,
  validateDateNotBeforeDate,
  validateMaxStringLength,
  validateDateNotInTheFuture,
} from 'utils/validators';
import { helperText } from 'utils/dates';
import { PropertyReportFieldType } from './data/constants';
import {
  Configurations,
  KindLienPropertyReportField,
  LienReportFieldsTouched,
  RegisterField,
  RegisterFieldResult,
  User,
} from '../types/types';

import { MAX_LENDER_OR_SERVICER_STRING_LENGTH } from './data/constants';

enum FieldsInRow {
  One = 1,
  Two = 2,
}

type FormData = {
  [key: string]: any;
};

type OnPropertyReportFieldsProps = {
  disabled: boolean;
  registerField: RegisterField;
  lienReportFieldsTouched: LienReportFieldsTouched;
  showTriggeredPublishErrors: boolean;
  lienKind: string;
  getRegisterProps: (fieldName: string, lienId: string) => RegisterFieldResult;
  formData: FormData;
};

export const OnPropertyReportFields = ({
  disabled,
  registerField,
  lienReportFieldsTouched,
  showTriggeredPublishErrors,
  lienKind,
  getRegisterProps,
  formData,
}: OnPropertyReportFieldsProps) => {
  const { lienRateTypes } = useConfigurations() as Configurations;
  const { user } = useCurrentUser() as User;

  const { kind, identifier: lienId, endOfTermDate, lienDate } = formData || {};

  const isLiteLienFormsFlag = user?.rolloutFlags.includes('lite_lien_forms_flag');

  const rateTypeOptions = useMemo(() => getRateTypeOptions(lienRateTypes, kind.toUpperCase()), [lienRateTypes, kind]);

  const { rows, settings } = getLienPropertyReportFieldsSettings(
    lienKind,
    isLiteLienFormsFlag,
  ) as KindLienPropertyReportField;
  const fieldsSettings = settings?.[lienKind];

  const renderField = (key: PropertyReportFieldType) => {
    switch (key) {
      case PropertyReportFieldType.Position:
        // TODO(EH-741): Use MuiTextInput here after fixing trailing zeros issue
        return (
          <CurrencyInput
            label="Lien position"
            mask={[
              {
                mask: Number,
                signed: false,
                allowDecimal: false,
                scale: 0,
              },
            ]}
            startAdornment=""
            disabled={disabled}
            isZeroDefaultOnBlur={false}
            {...fieldsSettings?.[key]}
            {...getRegisterProps(PropertyReportFieldType.Position, lienId)}
          />
        );

      case PropertyReportFieldType.OriginalAmount:
        return (
          <CurrencyInput
            label="Original amount"
            padFractionalZeros={true}
            disabled={disabled}
            {...fieldsSettings?.[key]}
            {...getRegisterProps(PropertyReportFieldType.OriginalAmount, lienId)}
          />
        );

      case PropertyReportFieldType.LienDate:
        return (
          <DatePicker
            label="Date recorded"
            theme="outlined"
            disabled={disabled}
            className="FullWidthDatePicker"
            wrapperClassName="FullWidthDatePicker"
            showRequiredAsterisk={false}
            showErrorIcon={true}
            {...fieldsSettings?.[key]}
            {...getRegisterProps(PropertyReportFieldType.LienDate, lienId)}
            helperText={helperText}
            error={
              (lienDate && validateDateNotInTheFuture(lienDate)) ||
              ((lienReportFieldsTouched[lienId]?.[PropertyReportFieldType.LienDate] || showTriggeredPublishErrors) &&
                !lienDate &&
                REQUIRED_ERROR_MESSAGE) ||
              ''
            }
          />
        );

      case PropertyReportFieldType.Holder:
        return (
          <MuiTextInput
            label="Servicer"
            theme="outlined"
            width="100%"
            disabled={disabled}
            required={true}
            showRequiredAsterisk={false}
            {...fieldsSettings?.[key]}
            {...registerField(PropertyReportFieldType.Holder)}
            validator={(value: string) => validateMaxStringLength(value, MAX_LENDER_OR_SERVICER_STRING_LENGTH)}
          />
        );

      case PropertyReportFieldType.EndOfTermDate:
        return (
          <DatePicker
            label="Term date"
            theme="outlined"
            disabled={disabled}
            className="FullWidthDatePicker"
            wrapperClassName="FullWidthDatePicker"
            showRequiredAsterisk={false}
            {...fieldsSettings?.[key]}
            {...getRegisterProps(PropertyReportFieldType.EndOfTermDate, lienId)}
            showErrorIcon={true}
            helperText={helperText}
            error={
              (endOfTermDate && validateDateNotBeforeDate(endOfTermDate, lienDate, 'Term date', 'Date recorded')) ||
              ((lienReportFieldsTouched[lienId]?.[PropertyReportFieldType.EndOfTermDate] ||
                showTriggeredPublishErrors) &&
                !endOfTermDate &&
                REQUIRED_ERROR_MESSAGE) ||
              ''
            }
          />
        );

      case PropertyReportFieldType.Rate:
        return (
          <MuiTextInput
            label="Rate"
            mask={[
              {
                mask: Number,
                radix: '.',
                scale: 3,
              },
            ]}
            unmask="typed"
            endAdornment="%"
            theme="outlined"
            width="100%"
            disabled={disabled}
            showRequiredAsterisk={false}
            {...fieldsSettings?.[key]}
            {...getRegisterProps(PropertyReportFieldType.Rate, lienId)}
          />
        );

      case PropertyReportFieldType.RateType:
        return (
          <MuiSelect
            label="Rate type"
            options={rateTypeOptions}
            disabled={disabled}
            theme="outlined"
            width="100%"
            showRequiredAsterisk={false}
            {...fieldsSettings?.[key]}
            {...getRegisterProps(PropertyReportFieldType.RateType, lienId)}
          />
        );

      case PropertyReportFieldType.Lender:
        return (
          <MuiTextInput
            label="Lender"
            theme="outlined"
            width="100%"
            disabled={disabled}
            required={true}
            showRequiredAsterisk={false}
            {...fieldsSettings?.[key]}
            {...registerField(PropertyReportFieldType.Lender)}
            validator={(value: string) => validateMaxStringLength(value, MAX_LENDER_OR_SERVICER_STRING_LENGTH)}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      {rows.map((row, i) => {
        if (row.length === FieldsInRow.One) {
          return (
            <div key={row[0]} className="FormRow">
              <div className="FormItem">{renderField(row[0])}</div>
            </div>
          );
        }
        if (row.length === FieldsInRow.Two) {
          return (
            <div key={i} className="TwoItemFormRow">
              {row.map(item => (
                <div key={item} className="FormItem">
                  {renderField(item)}
                </div>
              ))}
            </div>
          );
        }
        return null;
      })}
    </>
  );
};
